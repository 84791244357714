.sn-about-hero {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.sn-about-hero-inner {
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 20px 0;
}
.sn-about-title {
  font-size: 20px;
  font-weight: 700;
}
.sn-about-title span {
  background-color: var(--sn-link-bgrd);
  display: inline-block;
  padding: 5px 10px;
  color: var(--sn-white);
}
.sn-about-sub-title {
  font-size: 35px;
  font-weight: 600;
  color: var(--sn-white);
}

/* values */
.sn-our-values {
  padding: 50px 0;
  background: var(--sn-border-color);
}

.sn-section-title {
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 20px;
}

.sn-value {
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  text-align: center;
  height: 100%;
  border: solid 2px var(--sn-body-bg);
}

.sn-value h2 {
  font-size: 24px;
  margin-bottom: 15px;
  font-weight: 600;
}

.sn-value p {
  font-size: 16px;
  margin-bottom: 0;
}

/* values */

/* story */
.sn-our-story {
  padding: 50px 0;
}

.sn-our-story-content {
  text-align: center;
}

.sn-our-story-content h2 {
  font-size: 28px;
  margin-bottom: 20px;
  font-weight: 600;
}

.sn-our-story-content p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
}

/* story */

/* promise */
.sn-our-promise {
  padding: 50px 0;
}

.sn-promise-item {
  text-align: center;
  border: solid 2px var(--sn-border-color);
  padding: 10px;
  border-radius: 10px;
}

.sn-promise-icon {
  font-size: 36px;
  margin-bottom: 20px;
}

/* .sn-promise-icon svg {
    fill: #333; 
  } */

.sn-promise-item h3 {
  font-size: 24px;
  margin-bottom: 15px;
  font-weight: 600;
}

.sn-promise-item p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
}

/* promise */
