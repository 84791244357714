:root {
  --sn-sm-br-radius: 8px;
  --sn-body-bg: #e9e9e9;
  --bs-body-bg: #ffffff;
  --sn-link-text: #41003a;
  --sn-white: #f2f2f2;
  --sn-link-bgrd: #41003a;
  --sn-btn-srch: #75015b;
  --sn-ltbg: #ffd6ee;
  --sn-border-color: #ebebeb;
  --bs-border-color: #ebebeb;
  --sn-bg-gradient: linear-gradient(to left, #7b7b7b96, #7b7b7b96);
  --sn-text-white: #000000;
}
[data-bs-theme="dark"] {
  --bs-body-bg: #280024;
  --sn-body-bg: #41003a;
  --sn-link-text: #e1e1e1;
  --sn-white: #f2f2f2;
  --sn-link-bgrd: #41003a;
  --sn-btn-srch: #75015b;
  --sn-ltbg: #75015b;
  --sn-border-color: #41003a;
  --bs-border-color: #41003a;
  --sn-bg-gradient: linear-gradient(to left, #00000096, #00000096);
  --sn-text-white: #ffffff;
}
[data-bs-theme="light"] {
  --sn-body-bg: #e9e9e9;
  --bs-body-bg: #ffffff;
  --sn-link-text: #41003a;
  --sn-white: #f2f2f2;
  --sn-link-bgrd: #41003a;
  --sn-btn-srch: #75015b;
  --sn-ltbg: #ffd6ee;
  --sn-border-color: #ebebeb;
  --bs-border-color: #ebebeb;
  --sn-bg-gradient: linear-gradient(to left, #7b7b7b96, #7b7b7b96);
  --sn-text-white: #000000;
}
@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Mukta:wght@200;300;400;500;600;700;800&family=Ubuntu:wght@300;400;500;700&display=swap");
body {
  background: var(--bs-body-bg);
  font-family: "Kanit", sans-serif;
}

a,
.link {
  color: var(--sn-link-text);
  text-decoration: none;
}
main {
  min-height: 60vh;
}
.sn-link-btn {
  padding: 3px 10px;
  background-color: var(--sn-link-bgrd);
  /* border-radius: 3px; */
  border-bottom-left-radius: var(--sn-sm-br-radius);
  border-bottom-right-radius: var(--sn-sm-br-radius);
  color: var(--sn-white);
  text-decoration: none;
}

.sn-header {
  background: var(--sn-body-bg);
}
.sn-link-list {
  display: flex;
}
.sn-link-item:not(.btn-primary) {
  margin-right: 10px;
  color: var(--sn-text-white);
  text-decoration: none;

  padding: 3px 10px;
}
.sn-link-item:hover {
  background-color: var(--sn-link-bgrd);
  color: var(--sn-white);
  border-bottom-left-radius: var(--sn-sm-br-radius);
  border-bottom-right-radius: var(--sn-sm-br-radius);
}
.sn-link-list.custom-links span {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sn-logo {
  height: 60px;
}
.sn-logo-mb {
  max-height: 40px;
}
.nav-link.sn-cart {
  position: relative;
  margin-right: 18px;
}
.sn-theme-btn {
  font-size: 13px;
  padding: 0 10px;
  display: inline-block;
  border-radius: 0;
  text-align: center;
  border-bottom-left-radius: var(--sn-sm-br-radius);
  border-bottom-right-radius: var(--sn-sm-br-radius);
  background-color: var(--bs-body-bg);
  border: 0;
}
.sn-theme-btn svg {
  font-size: 20px;
}
.form-group {
  position: relative;
  overflow: hidden;
  border-radius: 7px;
}
.sn-search-header {
  background-color: var(--bs-primary);
}
.sn-related-search {
  border-bottom: solid 2px var(--sn-link-text);
}
.search-icon {
  border: 0;
  background-color: var(--sn-btn-srch);
  color: var(--sn-white);
  position: absolute;
  right: 0;
  top: 0;
  padding: 5px;
  height: 100%;
  border-bottom-right-radius: 7px;
  border-top-right-radius: 7px;
}
.sn-mobile-header {
  padding: 10px 0;
}
.sn-drawer-body {
  background: var(--bs-body-bg);
}
.sn-drawer-head {
  background-color: var(--bs-primary);
}

.sn-s-list {
  margin: 0;
  padding: 0;
  width: 100%;
}
.sn-s-list .sn-s-list-l {
  padding: 10px 0;
  display: block;
  border-bottom: solid 1px #c5c5c5;
  text-decoration: none;
  display: flex;
  font-weight: 500;
}

.sn-s-list .sn-s-list-l .sn-s-list-l-icn {
  margin-left: auto;
  display: block;
  font-size: 18px;
  background: var(--sn-ltbg);
  padding: 3px;
  border-radius: 3px;
  height: 22px;
  width: 22px;
}

.sn-cat-list-item {
  padding: 10px 15px;
  display: block;
  text-decoration: none;
  font-weight: 500;
}

.accordion a {
  text-decoration: none;
  width: 100%;
}

.sn-drw_head {
  background-color: var(--bs-primary);
}
.sn-drw_head .btn-close {
  background-color: white;
  padding-right: 15px;
}
.sn-login-btn {
  position: relative;
}
.sn-login-btn::after {
  display: block;
  position: absolute;
  top: calc(50% - 10px);
  right: 0;
  content: "";
  height: 20px;
  width: 3px;
  background-color: var(--sn-btn-srch);
}

.sn-banner-section {
  padding: 10px 0;
}
/* for mega menu */
.sn-mega-menu {
  border: solid 2px var(--sn-border-color);
  border-radius: 10px;
  width: 275px;
  position: relative;
  z-index: 99;
  background-color: var(--sn-body-bg);
}
.sn-mega-menu-list-link {
  display: flex;
  width: 100%;
  padding: 5px 10px;
  text-decoration: none;
}
.sn-mega-menu-list-link:hover {
  background-color: var(--sn-border-color);
}
.sn-mega-menu-list-link:hover .sn-mega-arrow-right {
  display: inline-block;
}
.sn-mega-menu-list-link svg {
  font-size: 20px;
  align-self: center;
  padding-right: 5px;
}
.sn-mega-menu-list-link .sn-mega-cat-img {
  width: 22px;
  margin-right: 10px;
}
.sn-mega-cat-img img {
  width: 100%;
}
.sn-mega-arrow-right {
  margin-left: auto;
  display: none;
}
.sn-mega-arrow-right svg {
  padding-right: 0;
  margin-top: -4px;
}

.sn-mega-menu-sub {
  position: absolute;
  height: 100%;
  width: 200px;
  border: solid 2px var(--sn-border-color);
  border-radius: 10px;
  left: calc(100% - 10px);
  background-color: var(--sn-body-bg);
  top: 0;
  display: none;
}
.sn-mega-menu-sec-sub {
  position: absolute;
  height: 100%;
  width: 200px;
  border: solid 2px var(--sn-border-color);
  border-radius: 10px;
  left: calc(100% - 10px);
  background-color: var(--sn-body-bg);
  top: 0;
  display: none;
}
.sn-mega-menu-item:hover .sn-mega-menu-sub {
  display: block;
}

.sn-mega-menu-sub .sn-mega-menu-item:hover .sn-mega-menu-sec-sub {
  display: block;
}

/* slider */
.sn-banner-slider {
  width: calc(100% - 280px);
  margin-left: 10px;
  border: solid 2px var(--sn-border-color);
  border-radius: 10px;
  overflow: hidden;
}

.sn-banner-slider.sn-mob {
  width: 100%;
  margin-left: 0;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sn-main-card {
  padding: 10px 0;
  border-radius: 10px;
  /* background: var(--sn-border-color); */
}
.sn-main-title {
  font-size: 30px;
  font-weight: 500;
}
.sn-main-content {
  padding-top: 10px;
}
.sn-cat-swipper {
  /* padding: 10px 0; */
  border-radius: 10px;
  overflow: hidden;
}
.sn-cat-card {
  border-radius: 10px;
  border: solid 2px var(--sn-border-color);
  width: 100%;
  background-color: var(--sn-body-bg);
  padding-top: 10px;
  padding-bottom: 5px;
  height: 150px;
}
.sn-cat-img {
  width: 100%;
  display: flex;
  align-items: center;
}
.sn-cat-img img {
  width: 70px;
  margin: auto;
}
.sn-cat-name {
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
  color: var(--sn-link-text);
}

/* product card */
.sn-product-card {
  border-radius: 10px;
  border-color: var(--sn-border-color);
  text-decoration: none;
  height: calc(100% - 1rem);
  margin-bottom: 1rem;
  overflow: hidden;
  background-color: var(--sn-body-bg);
}
.sn-offer-badge {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px 10px;
  background: var(--sn-btn-srch);
  color: var(--sn-white);
  border-bottom-right-radius: 10px;
}
.sn-product-card .card-title {
  font-size: 16px;
  font-weight: 500;
}
.sn-product-card .sn-card-img {
  height: 200px;
  display: flex;
}
.sn-product-card .sn-card-img img {
  object-fit: scale-down;
  background: var(--sn-white);
}
.sn-product-card .card-text {
  opacity: 0.6;
}

/* preloader */
/* styles.css */
@keyframes shining {
  0% {
    background-position: -200%;
  }
  100% {
    background-position: 200%;
  }
}
.sn-card-preloader {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
}

.sn-card-preloader .card-img-top {
  height: 200px;
  background-color: var(--sn-border-color);
  background: linear-gradient(
    90deg,
    transparent,
    var(--sn-border-color),
    transparent
  );
  background-size: 200% 100%;
  animation: shining 3s infinite linear;
}

.sn-card-preloader .placeholder-title,
.sn-card-preloader .placeholder-text {
  height: 16px;
  background-color: var(--sn-border-color);
  margin-bottom: 8px;
}

.sn-card-preloader .d-flex {
  align-items: center;
  justify-content: space-between;
}

.sn-card-preloader .placeholder-text {
  width: 30%;
  height: 16px;
  background-color: var(--sn-border-color);
}

.sn-banner-card {
  border-radius: 10px;
  border-color: var(--sn-border-color);
  text-decoration: none;
  overflow: hidden;
  height: calc(100%);
  padding-bottom: 20px;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}
.sn-banner-slider .mySwiper {
  max-height: 450px !important;
}
.mySwiper {
  height: 100%;
  width: 100%;
  max-height: 400px;
  /* padding: 20px 0; */
}
.product-gallery-container .mySwiper {
  padding: 20px 0;
}

.sn-filter-section {
  margin-bottom: 15px;
}

.sn-filter-item {
  margin-bottom: 8px;
  padding: 5px;
  transition: background-color 0.3s ease;
  cursor: pointer;
}
.sn-filter-item-link {
  display: flex;
  text-decoration: none;
}
.sn-filter-item:hover {
  background-color: var(--sn-body-bg);
}

.sn-filter-cat-img img,
.sn-filter-sub .sn-filter-item .sn-filter-cat-img img,
.sn-filter-sec-sub .sn-filter-item .sn-filter-cat-img img {
  width: 30px; /* Adjust the width as needed */
  height: 30px; /* Adjust the height as needed */
  border-radius: 50%;
  margin-right: 10px;
}

.sn-filter-sub {
  margin-left: 10px;
  display: none;
}

.sn-filter-sec-sub {
  margin-left: 30px;
  display: none;
}

.sn-filter-item:hover .sn-filter-sub {
  display: block;
}
.sn-filter-sub .sn-filter-item:hover .sn-filter-sec-sub {
  display: block;
}
.sn-view-btn {
  font-size: 16px;
  border-radius: 0;
  border: 0;
}
.sn-view-btn.active {
  background-color: var(--sn-btn-srch);
  color: var(--sn-white);
}
.sn-spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bs-body-bg);
  z-index: 99999;
}
.modal {
  z-index: 999999999999999;
}
.sn-bottom-nav {
  background-color: var(--sn-body-bg);
  border-top: solid 2px var(--sn-link-text);
}
.sn-bottom-nav .nav-link {
  color: var(--sn-link-text);
}

/* footer */

.footer-39201 {
  padding: 2rem 0;
  background: var(--sn-body-bg);
}
.footer-39201 .subscribe {
  position: relative;
}
.footer-39201 .subscribe .form-control {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 30px;
  height: 50px;
  padding-left: 30px;
  padding-right: 130px;
  border: none;
}
.footer-39201 .subscribe .btn-submit {
  background: var(--sn-btn-srch);
  height: 40px;
  border-radius: 30px;
  padding-left: 30px;
  padding-right: 30px;
  color: var(--sn-white);
  font-weight: bold;
  position: absolute;
  top: 5px;
  right: 5px;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.footer-39201 h3 {
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 20px;
  color: var(--sn-text-white);
}
.footer-39201 p {
  color: var(--sn-text-white);
}
.footer-39201 .nav-links li {
  display: block;
  margin-bottom: 10px;
}
.footer-39201 .nav-links li a {
  color: var(--sn-text-white);
}
.footer-39201 .social li {
  display: inline-block;
}
.footer-39201 .social li a {
  display: inline-block;
  padding: 10px;
  color: var(--sn-text-white);
}
.footer-39201 li a:hover {
  color: var(--sn-link-text);
}
.footer-39201 .payment-logos img {
  opacity: 0.7; /* Adjust opacity */
  margin-left: 10px;
}

.footer-39201 .payment-logos img:hover {
  opacity: 1; /* Change opacity on hover */
}
.sn-text-light {
  color: var(--sn-text-white);
}
.sn-main-foot-block {
  border-bottom: solid 2px var(--sn-link-text);
}

/* for auth */
.sn-auth-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sn-auth-cotain {
  max-width: 500px;
  background-color: var(--sn-border-color);
  border-radius: 10px;
  padding: 30px 15px;
  width: 100%;
}
.sn-background-img {
  height: 100%;
  width: 100%;
  min-height: 65vh;
  background: var(--sn-bg-gradient), url("./img/about-hero.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.sn-myac-sidebar {
  position: relative;
}
.sn-myac-sidebar::after {
  content: "";
  width: 2px;
  background-color: var(--bs-border-color);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}
.testimonial-card {
  background-color: var(--sn-body-bg);
  border: 0;
}

.testimonial-content {
  text-align: center;
}

.testimonial-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(
    --sn-btn-srch
  ) !important; /* You can change the color as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.testimonial-icon i {
  color: var(--sn-white); /* Icon color */
}
.sn-ttitle {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Style for feature box */
.feature-box {
  background-color: var(--sn-body-bg);
  padding: 10px 15px;
  border-radius: 10px;
  display: flex;
}
@media screen and (max-width: 768px) {
  .feature-box {
    margin-bottom: 10px;
  }
}
/* Style for icon */
.fbox-icon {
  text-align: center;
  margin-bottom: 15px;
}

/* Style for icon inner */
.icon-inner {
  background-color: var(--bs-body-bg);
  border-radius: 50%;
  padding: 20px;
  display: inline-block;
}

/* Style for icon */
.fbox-icon i {
  font-size: 24px;
  color: var(--sn-link-text);
}
.fbox-content {
  padding: 0 10px;
}
.ourservice-heading {
  font-size: 18px;
  margin-bottom: 15px;
  color: var(--sn-text-white);
}

/* Style for description */
.description {
  font-size: 14px;
  color: var(--sn-text-white);
  margin-bottom: 0;
}
.sn-p-filter {
  background: var(--sn-body-bg);
  border-radius: 10px;
  overflow: hidden;
  padding: 10px;
}
.sn-product-card .card-body span {
  font-size: 15px;
}
@media (max-width: 767.5px) {
  .footer-39201 {
    padding-bottom: 90px;
  }
  .sn-whatsapp {
    bottom: 60px !important;
  }
}
.sn-show {
  display: block !important;
}
.sn-filter-item-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.sn-filter-item-header button {
  margin-left: auto;
  padding: 0 11px;
  border: 0;
}
.sn-whatsapp {
  position: fixed;
  bottom: 20px;
  right: 20px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  background-color: #ffffff;
  color: #22c53b;
  z-index: 999999;
  box-shadow: 0 0px 35px #8d958d;
}
