.sn-contact-page {
  padding-bottom: 50px;
  padding-top: 20px;
}

.sn-contact-page h1 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.sn-contact-page .lead {
  font-size: 1.1rem;
  margin-bottom: 30px;
}

.sn-contact-page form {
  margin-top: 30px;
}

.sn-contact-page form .form-label {
  font-weight: bold;
}

.sn-contact-page form .form-control {
  margin-bottom: 20px;
}

.sn-contact-page form textarea.form-control {
  resize: vertical;
}

.sn-contact-page iframe {
  border: none;
  margin-top: 20px;
}
