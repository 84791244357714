.text-bold {
  font-weight: 600;
}

text-color {
  color: #0093c4;
}

/* Main image - left */
.main-img img {
  width: 100%;
}

/* Preview images */
.previews img {
  width: 100%;
  height: 140px;
}

.main-description .category {
  text-transform: uppercase;
  color: #0093c4;
}

.main-description .product-title {
  font-size: 30px;
}

.old-price-discount {
  font-weight: 600;
}

.new-price {
  font-size: 2rem;
}

.details-title {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.2rem;
  color: #757575;
}

.buttons .block {
  margin-right: 5px;
}

.quantity input {
  border-radius: 0;
  height: 40px;
}

.custom-btn {
  text-transform: capitalize;
  background-color: var(--sn-btn-srch);
  color: white;
  width: 150px;
  height: 40px;
  border-radius: 0;
}

.custom-btn:hover {
  background-color: var(--sn-btn-srch);
  color: white !important;
}

.similar-product img {
  height: 400px;
}

.similar-product {
  text-align: left;
}

.similar-product .title {
  margin: 17px 0px 4px 0px;
}

.similar-product .price {
  font-weight: bold;
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  /* Make preview images responsive  */
  .previews img {
    width: 100%;
    height: auto;
  }
}

/* Define zoom overlay styles */
.zoom-overlay {
  position: absolute;
  width: 200px; /* Adjust size as needed */
  height: 200px; /* Adjust size as needed */
  border: 2px solid #fff; /* Border color */
  background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent background */
  pointer-events: none; /* Allow mouse events to pass through */
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: 999; /* Ensure the overlay is above the image */
  display: none; /* Initially hidden */
}

/* Zoom effect on hover */
.zoom-on-hover {
  transition: transform 0.3s ease;
}

.sn-main-content .star-icon {
  color: #f5a623;
  cursor: pointer;
}

.sn-main-content .star-icon:hover {
  color: #f39c12;
}
